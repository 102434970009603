@media screen and (max-width: 600px) {
    body {
        background-size: cover;
    }
}
#particles-js {
    height: 100%;
}
.loginBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    min-height: 200px;
    background: #000000;
    border-radius: 10px;
    padding: 40px;
    box-sizing: border-box;
}
.user {
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
}
h4 {
    margin: 0;
    padding: 0 0 20px;
    color: #007bff;
    text-align: center;
}
.loginBox input,
button[type='submit'] {
    width: 100%;
    margin-bottom: 20px;
}
.loginBox input[type='email'],
.loginBox input[type='password'] {
    border: none;
    border-bottom: 2px solid #262626;
    outline: none;
    height: 40px;
    color: #fff;
    background: transparent;
    font-size: 16px;
    padding-left: 20px;
    box-sizing: border-box;
}
.loginBox input[type='email']:hover,
.loginBox input[type='password']:hover {
    color: #007bff;
    border: 1px solid #007bff;
}
.loginBox input[type='email']:focus,
.loginBox input[type='password']:focus {
    border-bottom: 2px solid #007bff;
}
.inputBox {
    position: relative;
}
.inputBox span {
    position: absolute;
    top: 10px;
    color: #262626;
}
.loginBox button[type='submit'] {
    border: none;
    outline: none;
    height: 40px;
    font-size: 16px;
    background: #007bff;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
}
.loginBox a {
    color: #262626;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    display: block;
}
a:hover {
    color: #007bff;
}
p {
    color: #0000ff;
}
