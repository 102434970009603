:root {
    font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
    padding: 0;
    background-color: rgb(15, 15, 15) !important;
    height: 100%;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-page {
    padding-top: 60px;
}

/*.text-page-title {
}*/

.box-page-content {
    padding: 15px;
    width: 100%;
}

.box-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.btn-table-edit {
    display: flex;
    justify-content: space-evenly;
}

.btn-table-edit button {
    margin-left: 3px;
    margin-right: 3px;
}

.form-label,
.form-check-label {
    color: white;
}

.form-control,
.form-select {
    background-color: rgb(31, 31, 31) !important;
    color: white !important;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.box-empty-list {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.text-empty-list {
    color: white;
    font-size: 25px;
    text-align: center;
}

.ico-empty-list {
    color: white;
    font-size: 50px;
    text-align: center;
}

.box-sad-walking {
    width: 180px;
    height: 180px;
    border-radius: 100px;
    background-color: rgb(32, 32, 32);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media print {
    @page {
        max-height: 100%;
        max-width: 100%;
    }

    * {
        margin: 0 !important;
        padding: 0 !important;
        color: #000 !important;
        background-color: #fff !important;
        font-size: 14px !important;
    }

    .no-print {
        display: none !important;
    }

    table {
        overflow-x: hidden !important;
        display: table !important;
    }

    tbody {
        display: table-row-group !important;
    }

    .btn,
    tbody,
    td,
    th,
    tr {
        --bs-table-accent-bg: transparent !important;
        border: 0px !important;
    }

    tr {
        border: #000 1px solid !important;
    }

    td,
    th {
        border-right: #000 1px solid !important;
    }

    p,
    span {
        color: #000 !important;
    }

    .text-page-title {
        font-size: 24px !important;
        margin-top: 20px !important;
    }

    #text-total-inscricao {
        font-size: 20px !important;
        margin-bottom: 10px !important;
    }
}
